import React, { useState, useEffect } from "react"
import { AgeModal } from "@modules/ageModal"
import { Age } from '@modules/agePopup'
import useCookie from 'react-use-cookie';

import "@css/main.css"

const Layout = ({ children }) => {
  const [open, setOpen] = useState(true)
  const toggleOpen = () => setOpen(prev => !prev)

  const [ageCookie, setAgeCookie] = useCookie('age-verified', false);

  const verifyAge = () => {
    setOpen(false);
    setAgeCookie(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 2000);
  }, []);

  return (
    <>
      <main className="w-full absolute top-0 left-0 bg-liaise-orange">{children}</main>
      {!ageCookie &&
        <AgeModal isOpen={open} >
          {open && <Age verifyAge={verifyAge} toggleOpen={toggleOpen} />}
        </AgeModal>
      }
    </>
  )
}

export default Layout
