import React from 'react'

export const Age = ({ toggleOpen, verifyAge }) => {
    return (
        <div className="popup grid grid-cols-12 bg-liaise-orange py-44">
            <span className="flex items-center justify-center col-start-2 md:col-start-3 col-end-12 md:col-end-11 text-5xl leading-5xl md:text-7xl md:leading-7xl font-extrabold text-center">ARE YOU OVER 18?</span>
            <div className="flex flex-col md:flex-row md:justify-between col-start-3 md:col-start-4 col-end-11 md:col-end-10 flavours gap-x-10 mt-6">
                <button onClick={verifyAge} className="btn">YES</button>
                <a href="https://google.com" className="btn mt-8 md:mt-0">NO</a>
            </div>
        </div>
    )
}

